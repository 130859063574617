<template>
	<v-container class="mt-4 ml-2">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  			<v-card-title primary-title>
	  		    <span class="text-subtitle-1">Plantel / Encargada </span>
	  		    <v-spacer></v-spacer>
	  		    <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="initialize"
              small
              tile
            >
              Actualizar
            </v-btn>
	  		  </v-card-title>

          <v-card-text>
            <v-row justify="end">
              <v-col cols="12" md="6" lg="4">
                <v-text-field
                  label="Buscar"
                  dense
                  filled
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>
  				  <v-data-table
  				    :headers="headers"
  				    :items="videos"
  				    class="elevation-0"
              :search="search"
              dense
              :items-per-page="videos.length"
              hide-default-footer
  				  >

  				    <template v-slot:item.encargadas="{ item }">
                <v-chip
                	class="mr-2" 
                	small 
                	v-for="( encargada, i ) in item.encargadas" 
                	:key="i" 
                	color="green" 
                	dark
                  @click="(encargadas_telefonos = [encargada]), (dialogTelefonos = true)"
                  close-icon="mdi-delete"
      						@click:close="deleteVendedora(encargada, item)"
      						text-color="white"
      						close
                >
                	{{ encargada.nombre_usuario }} 
                </v-chip>

                <v-chip
                	class="mr-2" 
                	v-if="!item.encargadas.length"
                	small
                > 
              		VACANTE 
              	</v-chip>

              	<v-btn color="orange" x-small icon @click="plantel = item, dialogPlantel = true">
              		<v-icon>mdi-plus</v-icon>
              	</v-btn>
              </template>

  				    <template v-slot:no-data>
  				      <v-btn
  				        color="primary"
  				        @click="initialize"
  				        small
  				      >
  				        Actualizar
  				      </v-btn>
  				    </template>
  				  </v-data-table>
              
          </v-card-text>

				</v-card>
		  </v-col>

	  </v-row>
    <v-dialog
	    v-model="dialogTelefonos"
	    max-width="900"
    >
      <v-card>
        <v-card-title primary-title >
          <v-icon left>mdi-phone</v-icon> Telefonos
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text >
          <v-data-table
            :headers="headersTelefonos"
            :items="encargadas_telefonos"
            class="elevation-0"
            dense
          >                 
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error"   dark @click="dialogTelefonos = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
	    v-model="dialogPlantel"
	    max-width="400"
    >
      <v-card>
        <v-card-title primary-title >
          Nueva encargada
        </v-card-title>
        <v-card-text v-if="plantel">
          <div v-for="(encargada, i) in plantel.encargadas">
          	<b>{{ encargada.nombre_usuario }}</b>
          </div>

          <br/>

          <v-autocomplete
	          filled
	          dense
	          clearable
	          v-model="usuario"
	          :items="usuarios"
	          label="Selecciona la vendedora"
	          persistent-hint
	          hide-details
	          item-text="nombre_usuario"
	          item-value="idusuario"
	        >
	        </v-autocomplete>

        </v-card-text>
        <v-card-actions>
          <v-btn 
          	color="black" 
          	tile 
          	small 
          	dark 
          	@click="dialogPlantel = false"
          >
          	<v-icon left small>mdi-cancel</v-icon>
        		Cancelar
        	</v-btn>
          <v-spacer></v-spacer>

          <v-btn 
          	color="green" 
          	tile 
          	small 
          	dark 
          	@click="save"
          >
          	<v-icon left small>mdi-content-save</v-icon>
        		Guardar
        	</v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

	  <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      dialogTelefonos: false,
      encargadas_telefonos : [],
      recepcionistas_telefonos : [],
      headers: [
        { text: 'Plantel', sortable: false, value: 'plantel', },
        { text: 'Encargada'               , value: 'encargadas' },
      ],
      headersTelefonos: [
        { text: 'Nombre'                    , value: 'nombre_completo' },
        { text: 'Telefono1'                 , value: 'telefono1' },
        { text: 'Telefono2'                 , value: 'telefono2' },
      ],
      videos: [],
      search:'',

      plantel: null,
      dialogPlantel: false,
      usuarios:[],
      usuario: null
    }),

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

    async created () {
      await this.initialize()
    },

    computed: {
      ...mapGetters("login", ["getdatosUsuario", "getLogeado"]),
    },

    methods: {
      initialize () {
        this.videos = []
        this.cargar = true
        return this.$http.get('vendedoras.plantel').then(response=>{
        	this.videos   = response.data.plantelesActivos
        	this.usuarios = response.data.personal
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {

      	this.alumno = item

       	if( item.escuela == 2 )
       		this.urlDirecta = this.urlFast + item.nombre_video
       	else
       		this.urlDirecta = this.urlInbi + item.nombre_video

        this.dialog = true
      },


      save(){
        this.cargar = true

        const payload = {
        	id_usuario: this.usuario,
        	plantel: this.plantel.idplantel
        }

        return this.$http.post('vendedoras.plantel', payload).then(response=>{
        	this.usuario = null
        	this.dialogPlantel = false
        	this.initialize()
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      	console.log( this.usuario, this.plantel )

      },

      deleteVendedora(vendedora, plantel){
      	this.cargar = true

        const payload = {
        	id_usuario: vendedora.idusuario,
        	plantel: plantel.idplantel
        }

        return this.$http.post('vendedoras.plantel.delete', payload).then(response=>{
        	this.usuario = null
        	this.dialogPlantel = false
        	this.initialize()
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
    },
  }
  /*454*/
</script>